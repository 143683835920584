import { useMutation, useQueryClient } from 'react-query';

import { noop } from 'dpl/shared/utils';

import createFetchData from '../utils/createFetchData';

export default function useResourceMutation({
  headers,
  body,
  method,
  params,
  url,

  // OPTIONAL react-query options
  config = {},
  createFetchDataConfig = {},

  // tpt-connect options
  // refetchAfter: what conditions to trigger a refetch under (if at all)
  // falsy: don't refetch (default)
  // true: always refetch resource after mutation
  // 'error': only refetch resource if request fails
  // 'success': only refetch resource if request succeeds
  refetchAfter,
  // updateStrategy: how to handle mutation response data in the cache
  // true/'replace': replace the query cache with the response data verbatim
  // fn: run the function with the old and new values as arguments
  updateStrategy = method === 'GET' ? 'replace' : false,

  // queryKey: the exact key for the corresponding query this mutation should update
  // REQUIRED if refetchAfter or updateStrategy is provided
  queryKey
}) {
  const { onSettled = noop, ...restConfig } = config;

  const queryClient = useQueryClient();

  return useMutation(
    (mutateArgs = {}) =>
      createFetchData({
        headers,
        body,
        method,
        params,
        url,
        ...createFetchDataConfig,
        ...mutateArgs
      }),
    {
      ...restConfig,

      onSettled: (data, error) => {
        if (
          refetchAfter === true ||
          (refetchAfter === 'error' && error) ||
          (refetchAfter === 'success' && !error)
        ) {
          queryClient
            .invalidateQueries(queryKey, { exact: true })
            .then(() => onSettled(data, error));
          return;
        }

        if (
          updateStrategy === true ||
          updateStrategy === 'replace' ||
          (updateStrategy === 'success' && !error)
        ) {
          queryClient.setQueryData(queryKey, data);
        } else if (typeof updateStrategy === 'function') {
          queryClient.setQueryData(queryKey, prevData =>
            updateStrategy(prevData, data)
          );
        }

        onSettled(data, error);
      }
    }
  );
}
